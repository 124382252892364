/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Mobilityways API
 * OpenAPI spec version: 2.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AbstractModuleApiResponseCheckEnabledAppVersionResponseJfi7eqa,
  AbstractModuleApiResponseCommunityFindByNameResponseAjfe7dq,
  AbstractModuleApiResponseCommunityOptionsKeyListResponseEndy3qi,
  AbstractModuleApiResponseCommunityOptionsListResponseL5yz2qy,
  AbstractModuleApiResponseCommunityPasswordValidateResponsePibql3i,
  AbstractModuleApiResponseSuggestCommunityByEmailResponseDlw3xdy,
  GetV2LiftshareCommunityFindByEmailParams,
  GetV2LiftshareCommunitySearchByNameParams,
  RequestDsp37ly
} from './model'
import { customInstance } from '../../axios-instance';




export const getV2LiftshareCommunityCommunityIdAppVersion = (
    communityId: MaybeRef<string>,
 signal?: AbortSignal
) => {
      communityId = unref(communityId);
      
      return customInstance<AbstractModuleApiResponseCheckEnabledAppVersionResponseJfi7eqa>(
      {url: `/v2/liftshare/community/${communityId}/app-version`, method: 'GET', signal
    },
      );
    }
  

export const getGetV2LiftshareCommunityCommunityIdAppVersionQueryKey = (communityId: MaybeRef<string>,) => {
    return ['v2','liftshare','community',communityId,'app-version'] as const;
    }

    
export const getGetV2LiftshareCommunityCommunityIdAppVersionQueryOptions = <TData = Awaited<ReturnType<typeof getV2LiftshareCommunityCommunityIdAppVersion>>, TError = AbstractModuleApiResponseCheckEnabledAppVersionResponseJfi7eqa>(communityId: MaybeRef<string>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV2LiftshareCommunityCommunityIdAppVersion>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  getGetV2LiftshareCommunityCommunityIdAppVersionQueryKey(communityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getV2LiftshareCommunityCommunityIdAppVersion>>> = ({ signal }) => getV2LiftshareCommunityCommunityIdAppVersion(communityId, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(communityId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getV2LiftshareCommunityCommunityIdAppVersion>>, TError, TData> 
}

export type GetV2LiftshareCommunityCommunityIdAppVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getV2LiftshareCommunityCommunityIdAppVersion>>>
export type GetV2LiftshareCommunityCommunityIdAppVersionQueryError = AbstractModuleApiResponseCheckEnabledAppVersionResponseJfi7eqa



export function useGetV2LiftshareCommunityCommunityIdAppVersion<TData = Awaited<ReturnType<typeof getV2LiftshareCommunityCommunityIdAppVersion>>, TError = AbstractModuleApiResponseCheckEnabledAppVersionResponseJfi7eqa>(
 communityId: MaybeRef<string>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV2LiftshareCommunityCommunityIdAppVersion>>, TError, TData>, }

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetV2LiftshareCommunityCommunityIdAppVersionQueryOptions(communityId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getV2LiftshareCommunitySearchByName = (
    params?: MaybeRef<GetV2LiftshareCommunitySearchByNameParams>,
 signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<AbstractModuleApiResponseCommunityFindByNameResponseAjfe7dq>(
      {url: `/v2/liftshare/community/search-by-name`, method: 'GET',
        params: unref(params), signal
    },
      );
    }
  

export const getGetV2LiftshareCommunitySearchByNameQueryKey = (params?: MaybeRef<GetV2LiftshareCommunitySearchByNameParams>,) => {
    return ['v2','liftshare','community','search-by-name', ...(params ? [params]: [])] as const;
    }

    
export const getGetV2LiftshareCommunitySearchByNameQueryOptions = <TData = Awaited<ReturnType<typeof getV2LiftshareCommunitySearchByName>>, TError = AbstractModuleApiResponseCommunityFindByNameResponseAjfe7dq>(params?: MaybeRef<GetV2LiftshareCommunitySearchByNameParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV2LiftshareCommunitySearchByName>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  getGetV2LiftshareCommunitySearchByNameQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getV2LiftshareCommunitySearchByName>>> = ({ signal }) => getV2LiftshareCommunitySearchByName(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getV2LiftshareCommunitySearchByName>>, TError, TData> 
}

export type GetV2LiftshareCommunitySearchByNameQueryResult = NonNullable<Awaited<ReturnType<typeof getV2LiftshareCommunitySearchByName>>>
export type GetV2LiftshareCommunitySearchByNameQueryError = AbstractModuleApiResponseCommunityFindByNameResponseAjfe7dq



export function useGetV2LiftshareCommunitySearchByName<TData = Awaited<ReturnType<typeof getV2LiftshareCommunitySearchByName>>, TError = AbstractModuleApiResponseCommunityFindByNameResponseAjfe7dq>(
 params?: MaybeRef<GetV2LiftshareCommunitySearchByNameParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV2LiftshareCommunitySearchByName>>, TError, TData>, }

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetV2LiftshareCommunitySearchByNameQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getV3LiftshareCommunityOptionsCommunityId = (
    communityId: MaybeRef<string>,
 signal?: AbortSignal
) => {
      communityId = unref(communityId);
      
      return customInstance<AbstractModuleApiResponseCommunityOptionsKeyListResponseEndy3qi>(
      {url: `/v3/liftshare/community/options/${communityId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetV3LiftshareCommunityOptionsCommunityIdQueryKey = (communityId: MaybeRef<string>,) => {
    return ['v3','liftshare','community','options',communityId] as const;
    }

    
export const getGetV3LiftshareCommunityOptionsCommunityIdQueryOptions = <TData = Awaited<ReturnType<typeof getV3LiftshareCommunityOptionsCommunityId>>, TError = AbstractModuleApiResponseCommunityOptionsKeyListResponseEndy3qi>(communityId: MaybeRef<string>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV3LiftshareCommunityOptionsCommunityId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  getGetV3LiftshareCommunityOptionsCommunityIdQueryKey(communityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getV3LiftshareCommunityOptionsCommunityId>>> = ({ signal }) => getV3LiftshareCommunityOptionsCommunityId(communityId, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(communityId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getV3LiftshareCommunityOptionsCommunityId>>, TError, TData> 
}

export type GetV3LiftshareCommunityOptionsCommunityIdQueryResult = NonNullable<Awaited<ReturnType<typeof getV3LiftshareCommunityOptionsCommunityId>>>
export type GetV3LiftshareCommunityOptionsCommunityIdQueryError = AbstractModuleApiResponseCommunityOptionsKeyListResponseEndy3qi



export function useGetV3LiftshareCommunityOptionsCommunityId<TData = Awaited<ReturnType<typeof getV3LiftshareCommunityOptionsCommunityId>>, TError = AbstractModuleApiResponseCommunityOptionsKeyListResponseEndy3qi>(
 communityId: MaybeRef<string>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV3LiftshareCommunityOptionsCommunityId>>, TError, TData>, }

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetV3LiftshareCommunityOptionsCommunityIdQueryOptions(communityId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Obsolete: Use V3 CommunityOptionsKeyList instead
 * @summary Obsolete: Use V3 CommunityOptionsKeyList instead
 */
export const getV2CommunityOptionsCommunityId = (
    communityId: MaybeRef<string>,
 signal?: AbortSignal
) => {
      communityId = unref(communityId);
      
      return customInstance<AbstractModuleApiResponseCommunityOptionsListResponseL5yz2qy>(
      {url: `/v2/community/options/${communityId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetV2CommunityOptionsCommunityIdQueryKey = (communityId: MaybeRef<string>,) => {
    return ['v2','community','options',communityId] as const;
    }

    
export const getGetV2CommunityOptionsCommunityIdQueryOptions = <TData = Awaited<ReturnType<typeof getV2CommunityOptionsCommunityId>>, TError = AbstractModuleApiResponseCommunityOptionsListResponseL5yz2qy>(communityId: MaybeRef<string>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV2CommunityOptionsCommunityId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  getGetV2CommunityOptionsCommunityIdQueryKey(communityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getV2CommunityOptionsCommunityId>>> = ({ signal }) => getV2CommunityOptionsCommunityId(communityId, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(communityId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getV2CommunityOptionsCommunityId>>, TError, TData> 
}

export type GetV2CommunityOptionsCommunityIdQueryResult = NonNullable<Awaited<ReturnType<typeof getV2CommunityOptionsCommunityId>>>
export type GetV2CommunityOptionsCommunityIdQueryError = AbstractModuleApiResponseCommunityOptionsListResponseL5yz2qy


/**
 * @summary Obsolete: Use V3 CommunityOptionsKeyList instead
 */

export function useGetV2CommunityOptionsCommunityId<TData = Awaited<ReturnType<typeof getV2CommunityOptionsCommunityId>>, TError = AbstractModuleApiResponseCommunityOptionsListResponseL5yz2qy>(
 communityId: MaybeRef<string>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV2CommunityOptionsCommunityId>>, TError, TData>, }

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetV2CommunityOptionsCommunityIdQueryOptions(communityId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const postV2LiftshareCommunityPasswordValidate = (
    requestDsp37ly: MaybeRef<RequestDsp37ly>,
 ) => {
      requestDsp37ly = unref(requestDsp37ly);
      
      return customInstance<AbstractModuleApiResponseCommunityPasswordValidateResponsePibql3i>(
      {url: `/v2/liftshare/community/password/validate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: requestDsp37ly
    },
      );
    }
  


export const getPostV2LiftshareCommunityPasswordValidateMutationOptions = <TError = AbstractModuleApiResponseCommunityPasswordValidateResponsePibql3i,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postV2LiftshareCommunityPasswordValidate>>, TError,{data: RequestDsp37ly}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postV2LiftshareCommunityPasswordValidate>>, TError,{data: RequestDsp37ly}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postV2LiftshareCommunityPasswordValidate>>, {data: RequestDsp37ly}> = (props) => {
          const {data} = props ?? {};

          return  postV2LiftshareCommunityPasswordValidate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostV2LiftshareCommunityPasswordValidateMutationResult = NonNullable<Awaited<ReturnType<typeof postV2LiftshareCommunityPasswordValidate>>>
    export type PostV2LiftshareCommunityPasswordValidateMutationBody = RequestDsp37ly
    export type PostV2LiftshareCommunityPasswordValidateMutationError = AbstractModuleApiResponseCommunityPasswordValidateResponsePibql3i

    export const usePostV2LiftshareCommunityPasswordValidate = <TError = AbstractModuleApiResponseCommunityPasswordValidateResponsePibql3i,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postV2LiftshareCommunityPasswordValidate>>, TError,{data: RequestDsp37ly}, TContext>, }
): UseMutationReturnType<
        Awaited<ReturnType<typeof postV2LiftshareCommunityPasswordValidate>>,
        TError,
        {data: RequestDsp37ly},
        TContext
      > => {

      const mutationOptions = getPostV2LiftshareCommunityPasswordValidateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getV2LiftshareCommunityFindByEmail = (
    params?: MaybeRef<GetV2LiftshareCommunityFindByEmailParams>,
 signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<AbstractModuleApiResponseSuggestCommunityByEmailResponseDlw3xdy>(
      {url: `/v2/liftshare/community/find/by-email`, method: 'GET',
        params: unref(params), signal
    },
      );
    }
  

export const getGetV2LiftshareCommunityFindByEmailQueryKey = (params?: MaybeRef<GetV2LiftshareCommunityFindByEmailParams>,) => {
    return ['v2','liftshare','community','find','by-email', ...(params ? [params]: [])] as const;
    }

    
export const getGetV2LiftshareCommunityFindByEmailQueryOptions = <TData = Awaited<ReturnType<typeof getV2LiftshareCommunityFindByEmail>>, TError = AbstractModuleApiResponseSuggestCommunityByEmailResponseDlw3xdy>(params?: MaybeRef<GetV2LiftshareCommunityFindByEmailParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV2LiftshareCommunityFindByEmail>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  getGetV2LiftshareCommunityFindByEmailQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getV2LiftshareCommunityFindByEmail>>> = ({ signal }) => getV2LiftshareCommunityFindByEmail(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getV2LiftshareCommunityFindByEmail>>, TError, TData> 
}

export type GetV2LiftshareCommunityFindByEmailQueryResult = NonNullable<Awaited<ReturnType<typeof getV2LiftshareCommunityFindByEmail>>>
export type GetV2LiftshareCommunityFindByEmailQueryError = AbstractModuleApiResponseSuggestCommunityByEmailResponseDlw3xdy



export function useGetV2LiftshareCommunityFindByEmail<TData = Awaited<ReturnType<typeof getV2LiftshareCommunityFindByEmail>>, TError = AbstractModuleApiResponseSuggestCommunityByEmailResponseDlw3xdy>(
 params?: MaybeRef<GetV2LiftshareCommunityFindByEmailParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV2LiftshareCommunityFindByEmail>>, TError, TData>, }

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetV2LiftshareCommunityFindByEmailQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



